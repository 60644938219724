<!--  -->
<template>
	<div class="home">
		<div class="swiper">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<div class="content-box">
						<div>
							<img
								class="phone-dn"
								src="@/assets/images/fonts.png"
								alt=""
							/>
							<img class="phone-db" src="@/assets/h5-images/fonts.png" alt="">
							<div style="padding:0" @click="show = true" :class="animateClass" class="banner-btn">申请试用></div>
						</div>
					</div>
					
				</div>
				<!-- <div class="swiper-slide">
					<div class="content-box">
						<div>
							<img
								src="@/assets/images/banner-11.png"
								alt=""
							/>
						</div>
					</div>
				</div> -->
			</div>
			<!-- 如果需要分页器 -->
			<div class="swiper-pagination"></div>
		</div>
		<van-overlay :show="show">
			<div class="commons-overlay-warp" @click.stop>
				<div class="title">申请试用系统 <i class="iconfont" @click="show = false">&#xe6b3;</i></div>
				<div class="label">
					具体申请方法
				</div>
				<div>1.咨询热线：<span style="color:#487CFF">0591-87865886</span></div>
				<div>2.扫描咨询：</div>
				<div class="ewm">
					<div class="ewm-box">
						<div class="ewm-warp">
							<img src="@/assets/images/img_ewm1.png" alt="" />
						</div>
						<p>企业微信客服</p>
					</div>
					<div class="ewm-box">
						<div class="ewm-warp">
							<img src="@/assets/images/img_gzhewm.png" alt="" />
						</div>
						<p>关注企业公众号</p>
					</div>
				</div>
			</div>
		</van-overlay>
		<div class="advantage">
			<div class="advantage-content">
				<div class="commons-big-size phone-dn"  :class="advantageAnimate">COMPANY ADVANTAGE</div>
				<h3 class="commons-h3 phone-dn"  :class="advantageAnimate">公司优势</h3>
				<p class="commons-p phone-dn"  :class="advantageAnimate">
					核心打造属于中小型企业的智能管理系统，提供便捷可靠的全方位服务
				</p>
				<div class="dn phone-db" :class="advantageAnimate">
					<img style="width:100%" src="@/assets/h5-images/h5_01.png" />
				</div>
				<ul class="phone-dn" :class="advantageAnimate">
					<li>
						<div class="label">简单易用</div>
						<div class="line">
							<div class="quan"></div>
						</div>
						<div class="img">
							<img
								class="img-box"
								src="@/assets/images/icon_jdyy_gray.png"
								alt=""
							/>
							<img
								class="img-box-active"
								src="@/assets/images/icon_jdyy_blue.png"
								alt=""
							/>
						</div>
						<div class="text">
							软件结构清晰、快捷菜单，即点即用，
							<br />资料关联，触手可及
						</div>
					</li>
					<li>
						<div class="label">全面高效</div>
						<div class="line">
							<div class="quan"></div>
						</div>
						<div class="img">
							<img
								class="img-box"
								src="@/assets/images/icon_qmgx_gray.png"
								alt=""
							/>
							<img
								class="img-box-active"
								src="@/assets/images/icon_qmgx_blue.png"
								alt=""
							/>
						</div>
						<div class="text">
							企业内部全流程可视化，<br />
							各项数据智能化抓取和分析，数据关联性强，
							<br />不易出错
						</div>
					</li>
					<li>
						<div class="label">低投入 高产出</div>
						<div class="line">
							<div class="quan"></div>
						</div>
						<div class="img">
							<img
								class="img-box"
								src="@/assets/images/icon_dtgc_gray.png"
								alt=""
							/>
							<img
								class="img-box-active"
								src="@/assets/images/icon_dtgc_blue.png"
								alt=""
							/>
						</div>
						<div class="text">
							时间，金钱，人员投入较少，<br />
							工作效率显著提高
						</div>
					</li>
					<li>
						<div class="label">实用性强</div>
						<div class="line">
							<div class="quan"></div>
						</div>
						<div class="img">
							<img
								class="img-box"
								src="@/assets/images/icon_syxq_gray.png"
								alt=""
							/>
							<img
								class="img-box-active"
								src="@/assets/images/icon_syxq_blue.png"
								alt=""
							/>
						</div>
						<div class="text">
							软件设计源自实践，用于实践，<br />
							行业资深人士参与设计
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="programme">
			<div class="programme-conetnt">
				<div class="commons-big-size" :class="programmeAnimete" style="color: #1b3a72">
					THE SOLUTION
				</div>
				<h3 style="color: #fff" class="commons-h3" :class="programmeAnimete">解决方案</h3>
				<p style="color: #fff" class="commons-p"  :class="programmeAnimete">
					针对不同的外贸服务公司，我们提供针对性的解决方案，为外贸企业轻量化提供一站式的智能管理，解决燃眉之急
				</p>
				<div class="programme-swiper"  :class="programmeAnimete">
					<div class="programme-nav">
						<div
							v-for="i in programmeList"
							:key="i.id"
							:class="i.id == programmeListType ? 'active' : ''"
							@click="programmeListType = i.id"
						>
							<i class="iconfont icon-1" v-html="i.icon"></i>
							<span>{{ i.name }}</span>
							<i class="iconfont icon--2">&#xe6b2;</i>
						</div>
					</div>
					<div class="programme-list-box">
						<div
							:style="
								'transform: translate(-0,-' +
								 programmeNum * programmeListType +
								'px);'
							"
						>
							<img src="@/assets/images/img1_khgl.jpg" alt="" />
							<img src="@/assets/images/img2_tdgl.jpg" alt="" />
							<img src="@/assets/images/img3_ddgl.jpg" alt="" />
							<img src="@/assets/images/img4_lcgl.jpg" alt="" />
							<img src="@/assets/images/img5_scgl.jpg" alt="" />
							<img src="@/assets/images/img6_ccgl.jpg" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="core">
			<div class="core-content">
				<div class="commons-big-size"  :class="coreAnimate">FUNCTION MODULE</div>
				<h3 class="commons-h3"  :class="coreAnimate">核心功能模块</h3>
				<p class="commons-p"  :class="coreAnimate">
					更多核心功能模块开发，为外贸企业提供更完善、便捷、高效的服务
				</p>
				<img class="phone-dn" :class="coreAnimate" src="@/assets/images/img_lct.png" alt="" />
				<img class="phone-db" :class="coreAnimate" src="@/assets/h5-images/img_lct@2x.png" alt="" />
			</div>
		</div>
		<div class="case">

			<div class="case-content">
				<div class="commons-big-size" :class="caseAnimate" style="color: #dddddd">
					CUSTOMER CASE
				</div>
				<h3 class="commons-h3" :class="caseAnimate">客户案例</h3>
				<p :class="caseAnimate" style="color: #999999" class="commons-p">
					创业公司、中小企业都值得信赖的软件服务
				</p>
				<div style="margin:30px 20px"  :class="caseAnimate" class="phone-db"> 
					<img src="@/assets/h5-images/h5_22.png" style="width:100%" alt="" />
					
				</div>
				<div :class="caseAnimate" class="swiper-container-2 phone-dn" style="overflow: hidden">
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
					<div class="swiper-wrapper">
						<div class="swiper-slide">
                            <div class="img">
                                <img src="@/assets/images/img_fzp.png" alt="">
                            </div>
                            <div class="text-warp">
                                <div class="text">
                                    <div class="logo">
                                        <img src="@/assets/images/logo200.png" alt="">
                                    </div>
                                    <h4>某纺织品外贸公司</h4>
                                    <p>某纺织品外贸公司，主营纺织品的出口业务，员工规模约200人，产品一万余种，年营业额8000万美金。通过数字云帆SAAS平台，在产品订单功能，改变传统订单贸易模式，实现时在线追踪最新数据，查询订单、跟单、售后等环节，提升贸易跟单效率。</p>
                                    <div class="shiyong">
                                        使用产品：<br>
                                        <span>数字云帆SAAS平台</span>
                                    </div>
                                    <div class="btn">了解更多></div>
                                </div>
                            </div>
                        </div>
						<div class="swiper-slide">
                            <div class="img">
                                <img src="@/assets/images/img_fzp.png" alt="">
                            </div>
                            <div class="text-warp">
                                <div class="text">
                                    <div class="logo">
                                        <img src="@/assets/images/logo200.png" alt="">
                                    </div>
                                    <h4>某纺织品外贸公司</h4>
                                    <p>某纺织品外贸公司，主营纺织品的出口业务，员工规模约200人，产品一万余种，年营业额8000万美金。通过数字云帆SAAS平台，在产品订单功能，改变传统订单贸易模式，实现时在线追踪最新数据，查询订单、跟单、售后等环节，提升贸易跟单效率。</p>
                                    <div class="shiyong">
                                        使用产品：<br>
                                        <span>数字云帆SAAS平台</span>
                                    </div>
                                    <div class="btn">了解更多></div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="img">
                                <img src="@/assets/images/img_fzp.png" alt="">
                            </div>
                            <div class="text-warp">
                                <div class="text">
                                    <div class="logo">
                                        <img src="@/assets/images/logo200.png" alt="">
                                    </div>
                                    <h4>某纺织品外贸公司</h4>
                                    <p>某纺织品外贸公司，主营纺织品的出口业务，员工规模约200人，产品一万余种，年营业额8000万美金。通过数字云帆SAAS平台，在产品订单功能，改变传统订单贸易模式，实现时在线追踪最新数据，查询订单、跟单、售后等环节，提升贸易跟单效率。</p>
                                    <div class="shiyong">
                                        使用产品：<br>
                                        <span>数字云帆SAAS平台</span>
                                    </div>
                                    <div class="btn">了解更多></div>
                                </div>
                            </div>
                        </div>
					</div>
					
				</div>
                
			</div>
		</div>
        <div class="trial">
            <p :class="trialAnimate">现在注册，即刻享受免费试用体验功能！</p>
            <div  :class="trialAnimate" class="btn phone-dn"  @click="show = true">
                立即试用
            </div>
        </div>
	</div>
</template>

<script>
import Vue from 'vue'

export default {
	data() {
		return {
			programmeListType: 0,
			programmeList: [
				{ name: '客户管理', id: 0, icon: '&#xe6ac;' },
				{ name: '团队管理', id: 1, icon: '&#xe6aa;' },
				{ name: '订单管理', id: 2, icon: '&#xe6b0;' },
				{ name: '流程管理', id: 3, icon: '&#xe6b1;' },
				{ name: '生产管理', id: 4, icon: '&#xe6af;' },
				{ name: '仓储管理', id: 5, icon: '&#xe6ae;' },
			],
			show:false,
			animateClass:"animate__animated animate__fadeInUp",
			advantageAnimate:"dn",
			programmeAnimete:"dn",
			coreAnimate:"dn",
			caseAnimate:"dn",
			trialAnimate:"dn",
			programmeNum:526,
		}
	},
	methods: {
		handleScroll(e){
			if(this.programmeNum == 526){
				if(document.documentElement.scrollTop > 200){
					this.advantageAnimate = this.animateClass
				}
				if(document.documentElement.scrollTop > 900){
					this.programmeAnimete = this.animateClass
				}
				if(document.documentElement.scrollTop > 1800){
					this.coreAnimate = this.animateClass
				}
				if(document.documentElement.scrollTop > 2900){
					this.caseAnimate = this.animateClass
				}
				if(document.documentElement.scrollTop > 3900){
					this.trialAnimate = this.animateClass
				}
			}else{
				if(document.documentElement.scrollTop > 200){
					this.advantageAnimate = this.animateClass
				}
				if(document.documentElement.scrollTop > 900){
					this.programmeAnimete = this.animateClass
				}
				if(document.documentElement.scrollTop > 1500){
					this.coreAnimate = this.animateClass
				}
				if(document.documentElement.scrollTop > 2400){
					this.caseAnimate = this.animateClass
				}
				if(document.documentElement.scrollTop > 2900){
					this.trialAnimate = this.animateClass
				}
			}
			
			
		},
	},
	created() {
		const v = this
		window.addEventListener('scroll',this.handleScroll)
		this.programmeNum = window.innerWidth > 800 ? 526 : 246
		setTimeout(() => {
			var swiper = new Swiper('.swiper-container-2', {
				slidesPerView: 1.8,
				spaceBetween: 30,
				centeredSlides: true,
				loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
				
			})
		}, 1500)
		// setTimeout(()=>{
		//     var mySwiper = new Swiper('.swiper', {

		// 	loop: true, // 循环模式选项

		// 	// 如果需要分页器
		// 	pagination: {
		// 		el: '.swiper-pagination',
		// 	},

		// 	// 如果需要前进后退按钮
		// 	navigation: {
		// 		nextEl: '.swiper-button-next',
		// 		prevEl: '.swiper-button-prev',
		// 	},

		// 	// 如果需要滚动条
		// 	scrollbar: {
		// 		el: '.swiper-scrollbar',
		// 	},
		// })
		// },500)
	},
}
</script>
<style>
.swiper-pagination-bullet {
	background: #fff;
	width: 20px;
	height: 4px;
	border-radius: 0px;
	opacity: 1;
}
.swiper-pagination-bullet-active {
	background: #487cff;
}
</style>
<style lang='less' scoped>

.swiper-container-2 {
    margin-top: 50px;
	padding: 100px 0;
    position: relative;
    .swiper-button-prev,.swiper-button-next{
        height: 40px;
        width: 40px;
        border-radius: 20px;
        background: #487CFF;
        color: #fff;
        
    }
    .swiper-button-prev:after,.swiper-button-next:after{
        font-size: 18px;
    }
	.swiper-slide {
		background: #fff;
        height: 280px;
		transition: 300ms;
		
        .img{
            width: 68.5%;
            height: 100%;
            float: left;
            padding: 4px;
            box-sizing: border-box;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .text-warp{
            width: 30%;
            float: right;
            .text{
                width: 320px;
                height: 400px;
                padding: 40px;
                transform: scale(0.7, 0.7);
                position: relative;
                left: -64px;
                top: -70px;
            }
            .logo{
                margin-bottom: 20px;
            }
            h4{
                font-size: 16px;
                color: #333;
                margin: 0 0 10px 0;
            }
            p{
                font-size: 12px;
                color: #333;
                line-height: 20px;
            }
            .shiyong{
                font-size: 12px;
                line-height: 20px;
                color: #333;
                margin: 10px 0 16px;
                span{
                    color: #487CFF;
                }
            }
            .btn{
                background: #487CFF;
                width: 100px;
                height: 32px;
                cursor: pointer;
                text-align: center;
                line-height: 32px;
                font-size: 12px;
                color: #fff;
            }
        }
	}
    .swiper-slide::before{
        position: absolute;
        display: block;
        content:"";
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.3);
    }
	.swiper-slide-active,
	.swiper-slide-duplicate-active {
		transform: scale(1.5, 1.5);
        z-index: 100;
	}
    .swiper-slide-active::before{
        background: none;
    }
}
.trial{
    background: url(../assets/images/img_ljsy.png) no-repeat;
	background-size: cover;
    height: 160px;
    p{
        color: #fff;
        font-size: 20px;
        padding-top: 35px;
        text-align: center;
    }
    .btn{
        width: 120px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #fff;
        font-size: 14px;
        color: #487CFF;
        margin: 0 auto;
        margin-top:30px;
        cursor: pointer;
        transition: all .5s ease;
    }
    .btn:active{
        background: #487CFF;
        color: #fff;
        
    }
}
.core {
	height: 1246px;
	background: url(../assets/images/img_hxgn.png) no-repeat;
	background-size: cover;
	.core-content {
		width: 1200px;
		margin: 0 auto;
		padding-top: 30px;
		img {
			width: 1100px;
			display: block;
			margin: 50px auto 0;
		}
	}
}
.case {
	height: 810px;
	background: url(../assets/images/img_khal.png) no-repeat;
	background-size: cover;
	.case-content {
		width: 1200px;
		margin: 0 auto;
		padding-top: 30px;
	}
}
.programme {
	height: 920px;
	background: url(../assets/images/img_jjfa.png) no-repeat;
	background-size: cover;

	.programme-conetnt {
		width: 1200px;
		margin: 0 auto;
		padding-top: 30px;
		.programme-swiper {
			display: flex;
			justify-content: space-between;
			margin-top: 80px;
		}
		.programme-list-box {
			width: 890px;
			border: 4px solid #fff;
			height: 530px;
			overflow: hidden;
			div {
				width: 100%;
				height: 100%;
				transition: all 0.5s ease;
			}
			img {
				width: 100%;
				height: 100%;
			}
		}
		.programme-nav {
			width: 300px;
			div {
				height: 80px;
				line-height: 80px;
				display: flex;
				justify-content: space-between;
				color: #fff;
				background: #224587;
				padding: 0 20px;
				margin-bottom: 10px;
				cursor: pointer;
				span {
					width: 75%;
					font-size: 20px;
				}
				.icon-1 {
					font-size: 30px;
				}
				.icon-2 {
					font-size: 20px;
				}
			}
			.active {
				background: linear-gradient(90deg, #487cff 3%, #3564f5);
			}
		}
	}
}

.advantage {
	height: 740px;
	background: #fff;
	.advantage-content {
		width: 1200px;
		margin: 0 auto;
		padding-top: 70px;
		ul {
			display: flex;
			margin-top: 80px;
			li {
				width: 25%;
				font-size: 12px;
				text-align: center;
				.label {
					width: 120px;
					height: 40px;

					color: #333;
					border-radius: 20px;
					line-height: 40px;
					margin: 0 auto 30px;
				}
				.line {
					height: 1px;
					background: #dddddd;
					margin-bottom: 30px;
					position: relative;
					.quan {
						width: 10px;
						height: 10px;
						position: absolute;
						border: 1px solid #999999;
						box-sizing: border-box;
						position: absolute;
						left: 50%;
						margin-left: -5px;
						border-radius: 5px;
						top: -5px;
					}
				}
				.text {
					color: #999999;
					margin-top: 20px;
				}
				.img-box-active {
					display: none;
				}
			}
			li:hover {
				.label {
					background: #487cff;
					color: #fff;
				}
				.line {
					.quan {
						background: #487cff;
						border-color: #487cff;
					}
				}
				.img-box-active {
					display: block;
					margin: 0 auto;
				}
				.img-box {
					display: none;
				}
				.text {
					color: #333;
				}
			}
		}
	}
}
.swiper {
	width: 100%;
	height: 100vh;
	min-height: 840px;
	.swiper-slide {
		overflow: hidden;
		background: #00072d;
		height: 100vh;
		min-height: 840px;
	}
	.banner-btn{
		margin: 0 auto;
		width: 180px;
		height: 60px;
		background: #487CFF;
		color:#fff;
		text-align: center;
		line-height: 60px;
		margin-top: 70px;
		cursor: pointer;
	}
	.banner-btn:active{
		background: #fff;
		color:#487CFF;
	}
	.content-box {
		background: url(../assets/images/banner840.png) no-repeat;
		height: 100vh;
		min-height: 840px;
		background-size: cover;
		margin: auto;
		div {
			padding-top: 300px;
		}
		img {
			display: block;
			margin: 0 auto;
		}
	}
}
@media only screen and (max-width: 800px) {
	.swiper{
		.content-box{
			img{
				width: 90%;
				margin: 0 5%;
			}
		}
	}
	.trial{
		height: 100px;
	}
	.case{
		.case-content{
			width: 100%;
		}
	}
	.core{
		height: auto;
		.core-content{
			width: 100%;
			img{
				width: 90%;
			}
		}
	}
	.programme{
		height: 600px;
		.programme-conetnt{
			width: 100%;
			padding: 35px 24px;
			.programme-swiper{
				display: block;
				margin-top: 35px;
				.programme-list-box{
					width: 100%;
					height: 250px;
				}
				.programme-nav{
					width: 100%;
					div{
						width: 30.33%;
						margin: 0 1.5% 10px;
						float:left;
						padding: 0;
						height: 45px;
						line-height: 45px;
						text-align: center;
						display: block;
						span{
							font-size: 12px;
							margin-left: 10px;
						}
						.icon-1{
							font-size: 12px;

						}
						.icon--2{
							display: none;
						}
					}
				}
			}
		}
	}
	.advantage{
		.advantage-content{
			width: 100%;
			padding: 35px 24px;
			ul{
				li{
					width: 100%;
					display: flex;
					.img-box{
						width: 60px;
						height: 60px;
					}
					.line{
						width: 1px;
						height: 62px;
					}
				}
			}
		}
	}
}
</style>